import { createI18n } from 'vue-i18n'
import en from '@/translations/en.json'
import es from '@/translations/es.json'

function useI18nComposable() {
  const i18n = createI18n({
    legacy: false,
    locale: (localStorage.getItem('locale') || 'en') as 'es' | 'en',
    messages: {
      en,
      es
    }
  })

  return { i18n }
}

export default useI18nComposable
