<template>
  <Dropdown class="">
    <template #activator="{ isOpen }">
      <div
        class="flex h-full items-center text-xs transition hover:text-teal-500"
        :class="{
          'text-teal-500 font-bold': isOpen,
          'text-zinc-800 dark:text-zinc-200  font-medium': !isOpen
        }"
      >
        <img
          :src="`/img/languages/${locale}.svg`"
          width="14"
          height="14"
          class="mr-1 inline rounded-full"
          alt=""
        />
        <div class="mr-1">{{ t('languages.' + locale) }}</div>
        <!--  <IconComponent
          name="ChevronDownIcon"
          size="xs"
          class="transition"
          :class="{
            'rotate-180': isOpen
          }"
        /> -->
      </div>
    </template>
    <template #content>
      <DropdownMenuItem
        v-for="(lang, i) in availableLocales"
        :key="`Lang${i}`"
        @click="setLocale(lang)"
        class="text-sm font-medium flex items-center text-zinc-800 dark:text-zinc-200"
      >
        <img
          :src="`/img/languages/${lang}.svg`"
          width="14"
          class="mr-1 inline rounded-full"
          alt=""
        />
        {{ t('languages.' + lang) }}
      </DropdownMenuItem>
    </template>
  </Dropdown>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useDayjsComposable from '@/composables/useDayjsComposable'
import Dropdown from '@/components/common/dropdown/Dropdown.vue'
import DropdownMenuItem from '@/components/common/dropdown/DropdownMenuItem.vue'
/* import IconComponent from '@/components/common/IconComponent.vue'
 */ const { locale, availableLocales, t } = useI18n()
const { setDayJsLocale } = useDayjsComposable()

function setLocale(lang: string) {
  locale.value = lang
  localStorage.setItem('locale', lang)
  setDayJsLocale(lang)
}
</script>
