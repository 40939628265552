import { defineStore } from 'pinia'
import { useI18n } from 'vue-i18n'

const usePostsStore = defineStore('posts', {
  state: () => {
    return {
      posts: [
        {
          date: '2023-12-18',
          title: { es: 'Compartir imagen', en: 'Share image' },
          description: {
            es: 'Tutorial para compartir imagen con navegador nativo.',
            en: 'Tutorial to share image with native navigator share.'
          },
          image: '',
          slug: 'share-image'
        },
        {
          date: '2023-10-23',
          title: { es: 'Calculadora de relación de relleno', en: 'Ratio padding calculator' },
          description: {
            es: 'Manera fácil de calcular la relación de aspecto de la imagen generando la relación.',
            en: 'Easy way to calculate image aspect ratio generating ratio.'
          },
          image: '',
          slug: 'ratio-padding-calculator'
        }
      ]
    }
  },

  getters: {
    getPosts: (state) => {
      const { locale } = useI18n()

      return state.posts.map((p: any) => ({
        ...p,
        title: p.title[locale.value as 'es' | 'en'],
        description: p.description[locale.value as 'es' | 'en']
      }))
    }
  }
})

export default usePostsStore
