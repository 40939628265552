//https://stackoverflow.com/questions/31173738/typescript-getting-error-ts2304-cannot-find-name-require
declare var require: any
require('dayjs/locale/es')
require('dayjs/locale/en')

import { computed } from 'vue'
import dayjs from 'dayjs'
import { default as duration } from 'dayjs/plugin/duration'
import { default as relativeTime } from 'dayjs/plugin/relativeTime'
import { useI18n } from 'vue-i18n'

dayjs.extend(duration)
dayjs.extend(relativeTime)

const useDayjsComposable = () => {
  const { locale } = useI18n()

  function setDayJsLocale(locale: string) {
    dayjs.locale(locale)
  }
  setDayJsLocale(locale.value)

  function dayjsFormat(date: string, format: string): string {
    return computed(() => {
      setDayJsLocale(locale.value)
      return dayjs(date).format(format)
    }).value
  }

  function differenceBetweenTwoDates(d1: string, d2: string) {
    return computed(() => {
      let durationTranslated = ''
      let yearString = ''
      let monthString = ''
      const date1 = dayjs(d1)
      const date2 = dayjs(d2)

      const duration = dayjs.duration(date2.diff(date1))
      // @ts-ignore: Unreachable code error
      const years = duration.$d.years
      // @ts-ignore: Unreachable code error

      const months = duration.$d.months

      if (years) {
        if (years == 1) {
          yearString = 1 + ' año '
        } else {
          yearString = years + ' años '
        }
      }

      if (months) {
        if (months == 1) {
          monthString = 1 + ' mes '
        } else {
          monthString = months + ' meses '
        }
      }

      return yearString + monthString
    }).value
  }

  return {
    dayjsFormat,
    dayjs,
    setDayJsLocale,
    differenceBetweenTwoDates
  }
}

export default useDayjsComposable
