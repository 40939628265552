<template>
  <div id="scroll-progress" class="bg-teal-500 h-1 fixed top-0 left-0 z-50"></div>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'
import { watch } from 'vue'

const { y } = useWindowScroll()

watch(
  () => y.value,
  (value) => {
    const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight
    const progress = (value / scrollableHeight) * 100
    const progressBar = document.getElementById('scroll-progress')

    if (progressBar) {
      progressBar.style.width = progress + '%'
    }
  }
)
</script>

<style scoped></style>
