<template>
  <footer class="mt-16 md:mt-32 flex-none mx-auto w-full px-8 md:px-20 lg:px-24">
    <div
      class="border-t border-zinc-100 dark:border-zinc-700/40 pb-16 pt-10 flex flex-col items-center justify-between gap-6 sm:flex-row"
    >
      <div
        class="flex flex-wrap justify-center gap-x-6 gap-y-1 text-sm font-medium text-zinc-800 dark:text-zinc-200"
      >
        <RouterLink
          :key="'footer-router-link-' + k"
          v-for="(link, k) in [
            {
              text: t('footer.about'),
              href: '/about'
            },
            {
              text: t('footer.dependencies'),
              href: '/project-dependencies'
            },
            {
              text: t('footer.setup'),
              href: '/uses'
            }
          ]"
          :to="link.href"
          class="transition hover:text-teal-500"
        >
          {{ link.text }}
        </RouterLink>

        <a target="_blank" href="https://coverage.brunomiguelbarrospinto.dev/">Coverage</a>
      </div>
      <p class="text-sm text-zinc-400 dark:text-zinc-500">
        <!-- © 2024 Bruno Miguel Barros Pinto. {{ t('footer.all_rights_reserved') }} -->
        {{ new Date().getFullYear() }} Bruno Miguel Barros Pinto.
      </p>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { RouterLink } from 'vue-router'

import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
