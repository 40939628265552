declare global {
  interface Window {
    dataLayer: any
  }
}

import './assets/css/index.css'

import * as Sentry from '@sentry/vue'

import App from './App.vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import router from './router'
import useI18nComposable from './composables/useI18nComposable'

const { i18n } = useI18nComposable()
const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://508036f7fbc4bb8218fa080ddbf23f46@o4507963114717184.ingest.de.sentry.io/4507963116814416',
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
app.use(i18n)
app.use(createPinia())
app.use(router)
app.mount('#app')

//SENTRY_AUTH_TOKEN=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
